<template>
  <fragment>
    <div class="heading-size-4 font-weight-black mb-2">
      {{ $t('share') }}
    </div>
    <div class="d-flex">
      <ShareNetwork
        v-for="(link, i) in links"
        :key="i"
        :network="link.network"
        :url="`https://neoone.art${pageUrl}`"
        :title="`${title} by ${artist} in NEO ONE ART`"
        description="You can buy shares of this work of art from NEO ONE ART"
        :quote="`${title} by ${artist} in NEO ONE ART`"
        :hashtags="link.hashtag"
        :popup="{ width: 400, height: 600 }"
        @open="openPopup"
      >
        <v-icon class="sns-icon" v-if="link.network === 'line'" size="30"
          >$vuetify.icons.line</v-icon
        >
        <v-icon class="sns-icon" v-else size="32">{{ link.icon }}</v-icon>
        <!-- <v-btn :key="i" icon color="grey" :href="link.url" target="_blank">
          <v-icon size="32">{{ link.icon }}</v-icon>
        </v-btn> -->
      </ShareNetwork>
    </div>
  </fragment>
</template>

<script>
import { analytics } from '@/helpers/firebase';

export default {
  name: 'SNS',
  props: {
    title: String,
    artist: String,
  },
  data: function() {
    return {
      pageUrl: '',
      lineIcon: require('@/assets/sns/line-share-grey.svg'),
      links: [
        // {
        //   icon: 'mdi-whatsapp',
        //   url: `https://wa.me/?text=Checkout%20this%20page.%20${this.pageUrl}`,
        //   hashtag: '',
        //   network: 'facebook'
        // },
        // {
        //   icon: 'mdi-instagram',
        //   url: `https://wa.me/?text=Checkout%20this%20page.%20${this.pageUrl}`,
        //   hashtag: '',
        //   network: '',
        // },
        {
          icon: 'mdi-facebook',
          url: `https://wa.me/?text=Checkout%20this%20page.%20${this.pageUrl}`,
          hashtag: '',
          network: 'facebook',
          img: '',
        },
        {
          icon: 'mdi-twitter',
          url: `http://twitter.com/share?text=""&url=https://neoone.art${this.$route.fullPath}`,
          hashtag: '',
          network: 'twitter',
          img: '',
        },
        {
          icon: 'mdi-line',
          url: `https://wa.me/?text=Checkout%20this%20page.%20${this.pageUrl}`,
          hashtag: '',
          network: 'line',
          img: require('@/assets/sns/line-share-grey.png'),
        },
      ],
    };
  },
  methods: {
    openPopup(network, url) {
      const path = new URL(url).pathname;
      analytics.logEvent('share', {
        method: network,
        content_type: 'artwork',
        item_id: `${path}`,
      });
    },
  },
  mounted() {
    this.pageUrl = this.$route.fullPath;
  },
};
</script>

<style lang="scss" scoped>
.sns-icon{
  margin-right: 15px;
}
</style>
