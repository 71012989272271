<template>
  <fragment>
    <h3 class="heading-size-4 font-weight-black mb-2">
      {{ $t('description') }}
    </h3>
    <div class="work-description">
      <div class="work-description__data">
        <div class="overline">{{ $t('year') }}</div>
        <div>{{ data.year }}</div>
      </div>
      <div class="work-description__data">
        <div class="overline">{{ $t('material') }}</div>
        <div>{{ data.material[$store.state.lang] }}</div>
      </div>
      <div class="work-description__data">
        <div class="overline">{{ $t('size') }}</div>
        <div>{{ `${data.height}x${data.width} ${units}` }}</div>
      </div>
      <div class="work-description__data">
        <div class="overline">{{ $t('location') }}</div>
        <div>{{ $t('japan') }}</div>
      </div>
    </div>
    <v-divider thickness class="mt-4" />
    <div>
      <div class="mt-4 unformatted-text">
        {{ data.long[$store.state.lang] }}
      </div>
      <div class="mt-8 unformatted-text">
        {{ JSON.parse(data.short[$store.state.lang]) }}
      </div>
    </div>
  </fragment>
</template>

<script>
export default {
  name: 'WorkDescription',
  props: {
    data: {
      type: Object,
      default() {
        return {
          year: '',
          material: '',
          height: '',
          width: '',
          short: { en: '""', es: '""', ja: '""' },
        };
      },
    },
  },
  data: () => ({
    units: 'cm',
  }),
};
</script>

<style lang="scss" scoped>
.work-description {
  @media only screen and (min-width: 500px) {
    display: flex;
    justify-content: space-between;
  }
  &__data {
    background-color: var(--color-grey-light);
    padding: 4px 8px;
    flex-grow: 1;
    border-left: 2px solid var(--color-white);
  }
}
</style>
