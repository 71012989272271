<template>
  <div
    class="neo-carrusel"
    :class="{
      'neo-carrusel--simple': value.length === 1,
      'neo-carrusel--complex': value.length !== 1,
    }"
  >
    <div
      v-if="value.length > 0"
      class="neo-carrusel__main"
      :style="`background-image: url('${value[activeImage].url}')`"
    ></div>
    <div class="neo-carrusel__thumbs" v-if="value.length > 1">
      <div
        v-for="(item, $index) in value"
        :key="item.id"
        class="neo-carrusel__thumb"
        :class="{ 'neo-carrusel__thumb--active': activeImage === $index }"
        :style="`background-image: url('${item.url}')`"
        @click="changeActiveImage($index)"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NeoCarrusel',
  props: {
    value: Array,
  },
  data: function() {
    return {
      activeImage: 0,
    };
  },
  methods: {
    changeActiveImage(imageClicked) {
      this.activeImage = imageClicked;
    },
  },
};
</script>

<style lang="scss" scoped>
.neo-carrusel {
  $this: &;
  height: 100%;
  width: 100%;
  display: grid;
  background: var(--color-grey);
  border-radius: 8px;
  padding: 12px;
  @media only screen and (min-width: 768px) {
    padding: 24px;
  }

  &--simple {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    min-height: inherit;
  }

  &--complex {
    grid-template-columns: 100px 1fr;
    grid-template-rows: calc(85% - 12px) 15%;
    grid-gap: 12px;
    min-height: inherit;
    @media only screen and (min-width: 768px) {
      grid-template-columns: 100px 1fr;
      grid-template-rows: auto auto;
      grid-gap: auto;
    }
  }

  &__main {
    background-size: contain;

    #{$this}--simple & {
      background-position: center center;
    }

    #{$this}--complex & {
      background-position: top center;
      grid-column: 1 / -1;
      @media only screen and (min-width: 768px) {
        grid-column: 2 / 3;
        grid-row: 1 / -1;
      }
    }
  }

  &__thumbs {
    margin-right: 12px;
    @media only screen and (max-width: 767px) {
      display: flex;
      grid-column: 1 / -1;
      justify-content: center;
    }
  }

  &__thumb {
    background-size: contain;
    background-position: center center;
    opacity: 0.5;
    height: 60px;
    min-width: 85px;
    margin-bottom: 12px;
    @media only screen and (min-width: 768px) {
      height: 86px;
    }
    @media only screen and (min-width: 1024px) {
      height: 100px;
    }
    &--active {
      opacity: 1;
    }
  }
}
</style>
