<template>
  <aside class="newsfeed">
    <h5 class="newsfeed__title heading-size-4">{{ $t("newsfeed") }}</h5>
    <div class="newsfeed__scroll" :v-if="items.length > 0">
      <ul class="newsfeed__list">
        <li class="newsfeed__item" v-for="(item, index) in items" :key="index">
          <a :href="item.url" target="_blank" :title="item.title">
            <img v-if="item.image.length > 0" :src="item.image" />
            <div>
              <h6
                class="newsfeed__itemtitle heading-size-4 font-weight-regular"
              >
                {{ item.title }}
              </h6>
              <div class="font-weight-light">{{ item.subtitle }}</div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
import NewsDS from "@/services/NewsDS.js";

export default {
  name: "NewsFeed",
  data() {
    return {
      body: "",
      items: [],
    };
  },
  props: {
    artworkId: String,
    artistId: String,
  },
  methods: {},
  mounted() {},
  watch: {
    artistId: async function () {
      let items = await NewsDS.read(this.artworkId);
      
      if (items.exists) {
        const artworkNews = items.data();
        if (artworkNews) this.items = this.items.concat(artworkNews[0]);
      } else {
        items = await NewsDS.read(this.artistId);
        if (items.exists) {
          const artworkNews = items.data();
          if (artworkNews) this.items = this.items.concat(artworkNews[0]);
        }
      }
      
    },
  },
};
</script>
<style lang="scss" scoped>
.newsfeed {
  margin-top: 40px;
  @media only screen and (min-width: 1024px) {
    border-left: 1px solid var(--color-grey);
    padding: 32px 8px 32px 32px;
    margin-top: 0;
  }
  &__title {
    display: inline-block;
    border-bottom: 4px solid var(--color-primary);
  }
  &__scroll {
    margin-top: 24px;
    max-height: 99%;
    // @media only screen and (min-width: 1024px) {
    //   overflow: auto;
    // }
    // @media only screen and (min-width: 1200px) {
    //   max-height: 99%;
    // }
  }
  &__item {
    margin-bottom: 24px;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--color-grey);
    &:last-child {
      border-bottom: 0;
    }
    a {
      color: currentColor;
      &:hover {
        color: var(--color-link);
      }
      // @media only screen and (min-width: 768px) {
      //   display: flex;
      //   align-items: flex-start;
      //   align-items: flex-start;
      // }
    }
    img {
      margin-right: 8px;
      margin-bottom: 12px;
      border: 2px solid var(--color-grey);
      float: left;
      // @media only screen and (min-width: 768px) {
      //   float: none;
      //   margin-bottom: 0;
      // }
    }
  }
  &__itemtitle {
    margin-bottom: 12px;
  }
}
</style>
