import { db } from '@/helpers/firebase';

const COLLECTION = 'News';
const collectionRef = db.collection(COLLECTION);

class NewsDS {

  async read(_id) {
    try{
      const news = await collectionRef.doc(_id).get();
      return news
    } catch {
      return []
    }
  }
}

export default new NewsDS();
