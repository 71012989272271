<template>
  <div class="artist-info-wrap">
    <div class="artist-info-wrap__layout">
      <ArtistInfo :artist="artistInfo" />
      <!-- <NewsFeed :queryString="value" /> -->
      <NewsFeed :artworkId="artworkId" :artistId="artistInfo.id" />
    </div>
  </div>
</template>

<script>
import ArtistInfo from '@/components/Organisms/ArtistInfo';
import NewsFeed from '@/components/Organisms/NewsFeed';
export default {
  name: 'ArtistInfoWrap',
  components: {
    NewsFeed,
    ArtistInfo,
  },
  props: {
    artistInfo: {
      type: Object,
      default: function() {
        return {
          id: '',
          artist: '',
          artistIntro: { en: '""', es: '""', ja: '""' },
          artistBio: { en: '""', es: '""', ja: '""' },
        };
      },
    },
    artworkId: String,
  },
  mounted() {},
};
</script>

<style lang="scss">
.artist-info-wrap {
  border-top: 1px solid var(--color-grey);
  margin-top: 40px;
  &__layout {
    max-width: var(--main-width-container);
    margin: auto;
    @media only screen and (min-width: 1024px) {
      display: grid;
      grid-gap: 24px;
      grid-template-columns: 2fr 1fr;
    }
    section {
      padding: 24px 0;
    }
  }
}
</style>
