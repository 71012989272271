<template>
  <section>
    <div class="header">
      <h2 class="heading-size-2 mb-4">
        {{ artist.artist }}
      </h2>
       <!-- <ul>
        <li v-for="(item, key, index) in items" v-bind:key="index">
          {{ item }} - {{ key }} - {{ index }}
        </li>
      </ul> -->
      <div  class="social">
        <v-btn
          v-for="(item, key, index) in artist.sns"
          :key="index"
          icon
          :href="item"
          target="_blank"
        >
          <v-icon color="base" size="24px">
           {{ `mdi-${key}` }}
          </v-icon>
        </v-btn>
      </div>
    </div>
    <div class="heading-size-3 mb-8 artist-intro">
      {{ JSON.parse(artist.artistIntro[this.$store.state.lang]) }}
    </div>

    <div>
      <div class="font-weight-light heading-size-4 unformatted-text">
        {{ JSON.parse(artist.artistBio[this.$store.state.lang]) }}
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    artist: {
      type: Object,
      default: function () {
        return {
          artist: "",
          artistIntro: { en: '""', es: '""', ja: '""' },
          artistBio: { en: '""', es: '""', ja: '""' },
          sns: undefined
        };
      },
    },
  },
  data() {
    return {
      items: {
        instagram: "https://www.instagram.com/sistifranz/",
        other: "",
        twitter: "",
      },
      items2: [{
        instagram: "https://www.instagram.com/sistifranz/",},{
        other: "",},{
        twitter: ""},
      ]
      // items: [
      //   // { icon: 'mdi-facebook', url: '' },
      //   { icon: "mdi-twitter", url: "https://twitter.com/NeoOneArt" },
      //   {
      //     icon: "mdi-instagram",
      //     url: "https://www.instagram.com/neoone.art/",
      //   },
      // ],
    };
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;  
}

.social {
  margin-bottom: 13px
}
.artist-intro {
  color: var(--color-primary);
}
</style>
